<h3>Store details</h3>
<form #orderForm="ngForm" (ngSubmit)="save()">
  <fieldset>
    <div class="row">
      <div class="col">
        <div class="form-group">
          <label for="name">Store Name</label>
          <input type="text" class="form-control" required minlength="2"
            [(ngModel)]="store.name" name="name" id="name" #name="ngModel"
            placeholder="Name" />
        </div>
        <div class="form-group">
          <label for="customerNo">Customer Number</label>
          <input type="text" class="form-control" required minlength="8"
           [(ngModel)]="store.customerNo" name="customerNo" id="customerNo" 
           #address="ngModel" placeholder="Customer Number" />
          <small id="customerNo" class="form-text text-muted">The J.Lindebergs internal store code. Used to tag which store the order was paid in.</small>
        </div>
      </div>
      <div class="col">
        <div class="form-group">
          <label for="key">Vendo Store code</label>
          <input type="text" class="form-control" [(ngModel)]="store.key" 
            name="key" id="key" #address="ngModel" disabled="true" />
        </div>
        <div class="form-group">
          <label for="email">Store Email</label>
          <input type="email" class="form-control" required minlength="5"
            [(ngModel)]="store.email" name="email" id="email"
            #email="ngModel" placeholder="Email" />
        </div>
      </div>
      <div class="col">
        <div class="form-group">
          <label for="sitoo">Sitoo instance</label>
          <ng-select [items]="sitoo$ | async" [(ngModel)]="store.sitoo" bindLabel="name" id="sitoo" name="sitoo" #store.sitoo="ngModel" ngDefaultControl></ng-select>

        </div>
        <div class="form-group">
          
        </div>
      </div>
    </div>

  </fieldset>
  <button type="submit" class="btn btn-dark" [disabled]="!orderForm.form.valid" >Save</button>
  <button type="button" routerLink="/admin" class="btn">Back</button>
</form>
<div *ngIf="store.key">http://checkout.jlindeberg.com/#/store/{{store.key}}</div>