import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AdminComponent } from './admin.component';
import { AdminStoreComponent } from './admin-store/admin-store.component';
import { AdminStoreListComponent } from './admin-store-list/admin-store-list.component';

import { LoginComponent } from './login/login.component';

import { AuthService as Auth } from './auth.service';

const routes: Routes = [
  { path: 'login', component: LoginComponent },
  { path: 'admin', component: AdminComponent, canActivate: [Auth],
    children: [
      { path: '', component: AdminStoreListComponent },
      { path: 'add', component: AdminStoreComponent },
      { path: ':id', component: AdminStoreComponent },
  ]},
];

@NgModule({
  imports: [
    RouterModule.forChild(routes)
  ],
  exports: [RouterModule]
})
export class AdminRoutingModule { }
