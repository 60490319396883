<div *ngIf="order" class="row">
  <div class="col"><h3>Order {{order.orderId | slice:0:8 }} </h3></div>
  <div class="w-100"></div>
  <div class="col">
  <table class="table">
    <thead>
      <tr>
        <th>Product</th>
        <th>Quantity</th>
        <th>Price</th>
        <th>Total Price</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let row of order.rows">
        <td>{{row.articleNumber}}</td>
        <td>{{row.quantity}}</td>
        <td>{{row.pricePerItem | currency:order.currencyUsed}}</td>
        <td>{{row.totalPrice | currency:order.currencyUsed}}</td>
      </tr>
    </tbody>
    <tfoot>
      <tr>
        <td></td>
        <td></td>
        <td></td>
        <td>{{ order.totalPrice | currency:order.currencyUsed }}</td>
      </tr>
    </tfoot>
  </table>
  </div>
  <div class="w-100"></div>
  <div class="col">
    <h3>Delivery details</h3>
    <form (ngSubmit)="onSubmit()" #orderForm="ngForm">
      <fieldset [disabled]="locked">
        <div class="row">
          <div class="col">
            <input type="text" class="form-control" required minlength="2"
                [(ngModel)]="order.deliveryAddress.firstName" name="firstName"
                #firstName="ngModel" placeholder="Firstname" />
            <input type="text" class="form-control" required minlength="2"
                [(ngModel)]="order.deliveryAddress.address1" name="address1"
                #address="ngModel" placeholder="Address 1" />
            <input type="text" class="form-control"
                [(ngModel)]="order.deliveryAddress.address2" name="address2"
                #address2="ngModel" placeholder="Address 2" />
            <input type="email" class="form-control"
                [(ngModel)]="order.userDetails.email" name="email"
                #email="ngModel" placeholder="Email" />
            <input type="text" class="form-control" required minlength="7"
                [(ngModel)]="order.userDetails.phone" name="phone"
                #phone="ngModel" placeholder="Phone" />
          </div>
          <div class="col">
            <input type="text" class="form-control" required minlength="2"
                [(ngModel)]="order.deliveryAddress.familyName" name="familyName"
                #familyName="ngModel" placeholder="Surname" />
            <input type="text" class="form-control" required minlength="2"
                [(ngModel)]="order.deliveryAddress.zip" name="postalcode"
                #postalcode="ngModel" placeholder="Postalcode" />
            <input type="text" class="form-control" required minlength="2"
                [(ngModel)]="order.deliveryAddress.city" name="city"
                #city="ngModel" placeholder="City" />
            <!-- <input type="text" class="form-control" required minlength="2" maxlength="2"
                [(ngModel)]="order.deliveryAddress.countryCode" name="country"
                #country="ngModel" placeholder="Country code 2 characters (SE,NO,DK,FI,GB etc)" /> -->
            <div class="form-group">
            <ng-select [items]="countries" [disabled]="locked" class="form-control" name="country" #country="ngModel" [(ngModel)]="order.deliveryAddress.countryCode" required>
            </ng-select>
            </div>
            <input type="text" class="form-control" id="status" disabled="true"
                [(ngModel)]="order.status.name" name="status" #status="ngModel">
          </div>
        </div>
        
      </fieldset>
      <div class="row">
        <div class="col-auto mr-auto">
          <button type="button" (click)="onSubmit('paid')" class="btn btn-lg btn-success" [disabled]="locked || !orderForm.form.valid">Save and mark as Paid</button>
          <button type="button" (click)="onSubmit('cancelled')" class="btn btn-lg btn-danger" [disabled]="locked || !orderForm.form.valid">Save and mark as Cancelled</button>
          <button type="button" (click)="onSubmit('')" class="btn btn-lg" [disabled]="locked || !orderForm.form.valid">Save</button>
        </div>
        <div class="col-auto">
          <button type="button" routerLink="/store/{{order.store.key}}" class="btn btn-lg">Back</button>
        </div>
      </div>
    </form>    
  </div>
</div>