import { Component, OnInit } from '@angular/core';
import { TraceClassDecorator } from '@sentry/angular';

@Component({
  selector: 'app-page-not-found',
  templateUrl: './page-not-found.component.html',
  styleUrls: ['./page-not-found.component.css']
})
@TraceClassDecorator()
export class PageNotFoundComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
