import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpClient } from '@angular/common/http';

import { StoreService } from '../store.service';

import { Order } from '../order';
import { Address } from '../address';
import { TraceClassDecorator } from '@sentry/angular';

@Component({
  selector: 'app-order',
  templateUrl: './order.component.html',
  styleUrls: ['./order.component.css']
})
@TraceClassDecorator()
export class OrderComponent implements OnInit, OnDestroy {
  order: Order;
  store: string;
  private sub: any;
  locked: boolean;
  countries: string[] = ['SE', 'NO', 'DK', 'FI', 'GB'];

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private http: HttpClient,
    private storeService: StoreService,
  ) {}

  ngOnInit() {
    this.route.parent.paramMap.subscribe(params => this.store = params.get('store'));
    this.sub = this.route.paramMap.subscribe(params => {
      console.log('api/' + this.store + '/orders/' + params.get('id'));
      if (params.get('id')) {
        this.http
        .get('api/' + this.store + '/orders/' + params.get('id'))
        .subscribe(
          (data: Order) => {
            if (!data.deliveryAddress) {
              data.deliveryAddress = new Address();
            }
            this.order = data;
            this.locked = this.order.status.name === 'Paid' || this.order.sitooOrderId !== null;
          },
          err => console.log(err)
        );
      }
    });
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }

  onSubmit(status) {
    if (status === 'paid') {
      this.order.status.id = 2;
    } else if (status === 'cancelled') {
      this.order.status.id = 3;
    }
    const putUrl = 'api/' + this.order.store.key + '/orders/' + this.order.id;
    this.http
    .put(putUrl, this.order)
    .subscribe(
      () => {
        this.storeService.getStore();
        this.storeService.getOrders().subscribe();
        this.router.navigate(['/store/' + this.store ]);
      },
      err => console.log(err)
    );

  }
}
