import { Sitoo } from './sitoo';

export class Store {
    id: number;
    name: string;
    key: string;
    customerNo: string;
    email: string;
    sitooWarehouseId: string;
    sitoo: Sitoo;
}
