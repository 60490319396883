import { NgModule, ErrorHandler, Injectable } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';

import { NgSelectModule } from '@ng-select/ng-select';

import * as Sentry from '@sentry/angular';
import { Integrations } from '@sentry/tracing';

import { AppComponent } from './app.component';
import { environment } from './../environments/environment';

import { StoreComponent } from './store/store.component';
import { OrderComponent } from './store/order/order.component';
import { OrderListComponent } from './store/orderList/orderList.component';

import { AdminComponent } from './admin/admin.component';
import { AdminStoreComponent } from './admin/admin-store/admin-store.component';
import { AdminStoreListComponent } from './admin/admin-store-list/admin-store-list.component';

import { AppRoutingModule } from './app-routing.module';
import { AdminRoutingModule } from './admin/admin-routing.module';
import { StoreRoutingModule } from './store/store-routing.module';

import { StoreService } from './store/store.service';
import { WebsocketService } from './store/websocket.service';
import { AuthService } from './admin/auth.service';
import { LoginComponent } from './admin/login/login.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { Router } from '@angular/router';
import { TraceDirective, TraceService } from '@sentry/angular';


Sentry.init({
  dsn: 'https://6bd0fb022b4b4fc988f531c082a4de82@sentry.io/1552297',
  integrations: [new Integrations.BrowserTracing({
    tracingOrigins: ['localhost', 'checkout.jlindeberg.com', /^\//],
    routingInstrumentation: Sentry.routingInstrumentation,
  })],
  environment: environment.production ? 'production' : 'development',
  tracesSampleRate: 1
});

@NgModule({
  declarations: [
    AppComponent,
    StoreComponent,
    OrderListComponent,
    OrderComponent,
    AdminComponent,
    AdminStoreComponent,
    AdminStoreListComponent,
    LoginComponent,
    PageNotFoundComponent
  ],
  imports: [
    BrowserModule,
    FormsModule,
    HttpClientModule,
    NgSelectModule,
    AppRoutingModule,
    AdminRoutingModule,
    StoreRoutingModule,
  ],
  providers: [
    AuthService,
    StoreService,
    WebsocketService,
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false,
      }),
    },
    {
      provide: TraceService,
      deps: [Router],
    },
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
