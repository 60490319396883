
import {interval as observableInterval,  Subscription ,  Observable } from 'rxjs';
import { Component, OnInit, OnDestroy, AfterViewInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';


import { StoreService } from '../store.service';
import { Store } from '../store';
import { Order } from '../order';
import { TraceClassDecorator } from '@sentry/angular';

@Component({
  selector: 'app-order-list',
  templateUrl: './orderList.component.html',
  styleUrls: ['./orderList.component.css']
})
@TraceClassDecorator()
export class OrderListComponent implements OnInit, OnDestroy, AfterViewInit {
  orders: Order[];
  _orders: Order[];
  orders$: Observable<Order[]>;
  status: number;
  private subscription: Subscription;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private storeService: StoreService,
  ) {
    this.status = this.storeService.getStatus();
    this.orders = this.storeService.orders.getValue();
    this.orders$ = this.storeService.subOrders();
  }

  ngOnInit() {
    this.orders = this.storeService.orders.getValue();
    this.subscription = this.storeService.subOrders()
    .subscribe(orders => {
      this._orders = orders;
    });
  }

  filter() {
    this.storeService.setStatus(this.status);
    this.storeService.getOrders().subscribe();
  }

  goToOrder(storeKey: string, order: Order) {
    this.router.navigate(['/store/' + storeKey + '/order/' + order.id]);
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  ngAfterViewInit(): void {
    observableInterval(500)
      .subscribe(i => {
        this.orders = this._orders;
      });
  }
}
