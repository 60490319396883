import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Sitoo } from './sitoo';
import { Store } from './store';


@Injectable({
  providedIn: 'root'
})
export class ConnectorBackendService {

  constructor(private http: HttpClient) { }

  get_sitoos(): Observable<Sitoo[]> {
    return this.http.get<Sitoo[]>('/api/sitoo');
  }

  get_stores(): Observable<Store[]> {
    return this.http.get<Store[]>('/api/store');
  }

  get_store(id: string): Observable<Store> {
    return this.http.get<Store>('/api/' + id);
  }

  post_store(body: Store ) {
    return this.http.post('/api/store', body);
  }

  put_store(body: Store) {
    return this.http.put('/api/' + body.key, body);
  }
}
