<div class="container-fluid">
    <div class="row">
        <div class="col store d-flex flex-column justify-content-center">
            <h1>J.LINDEBERG</h1>
            <h3>{{store.name | uppercase}}</h3>
            <div class="p-2 info">
                <span class="number">{{store.paid}}</span> <br />
                paid
            </div>
            <div class="p-2 info">
                <span class="number">{{store.unpaid}}</span> <br />
                unpaid
            </div>
            <div class="mt-auto p-2">
                <hr>
                <h4>SUPPORT VERTISEIT</h4>
                <p>+46 (0)340-84812 <br />support@vertiseit.se</p>
            </div>
        </div>
        <div class="col-9 col-xl-9 col-md-8 content">
            <router-outlet></router-outlet>
        </div>
    </div>
</div>