<div *ngIf="orders">
  <div class="d-flex">
      <div class="p-2">
        <h2>ORDER LIST</h2>
      </div>
      <div class="ml-auto p-2">
        <label for="status">Status</label>
        <select class="custom-select" id="status" [(ngModel)]="status" name="status" #statuss="ngModel" (change)="filter()">
          <option value="1" [selected]="status == 1">Unpaid</option>
          <option value="2" [selected]="status == 2">Paid</option>
          <option value="3" [selected]="status == 3">Cancelled</option>
        </select>
      </div>
  </div>

  <table class="table">
    <thead>
      <tr>
        <th>Order number</th>
        <th>Price</th>
        <th>Date</th>
        <th>Time</th>
        <th>Status</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let order of orders" (click)="goToOrder(order.store.key, order)">
        <td>{{ order.orderId | slice:0:8 }}</td>
        <td>{{ order.totalPrice | currency: order.currencyUsed:'symbol-narrow' }}</td>
        <td>{{ order.createdDate | date:'dd/MM'}}</td>
        <td>{{ order.createdDate | date:'HH:mm'}}</td>
        <td>{{ order.status.name }}</td>
      </tr>
    </tbody>
  </table>
</div>