import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';

import { Store } from '../store';
import { ConnectorBackendService } from '../connector-backend.service';
import { Sitoo } from '../sitoo';
import { Observable } from 'rxjs';
import { TraceClassDecorator } from '@sentry/angular';

@Component({
  selector: 'app-admin-store',
  templateUrl: './admin-store.component.html',
  styleUrls: ['./admin-store.component.css']
})
@TraceClassDecorator()
export class AdminStoreComponent implements OnInit {

  store = new Store();
  newStore = true;
  sitoo$: Observable<Sitoo[]>;
  constructor(
    private router: Router,
    private http: HttpClient,
    private route: ActivatedRoute,
    private connectorBackend: ConnectorBackendService
  ) { }

  ngOnInit() {
    this.sitoo$ = this.connectorBackend.get_sitoos();
    this.sitoo$.subscribe();
    this.route.paramMap.subscribe(params => {
      this.connectorBackend.get_store(params.get('id'))
        .subscribe(
          (store: Store) => { this.store = store; this.newStore = false; },
          (err: HttpErrorResponse) => { this.newStore = true; }
        );
    });
  }

  save() {
    if (this.newStore) {
      this.connectorBackend.post_store(this.store).subscribe(
        () => this.router.navigate(['/admin/']),
        err => console.log(err)
      );
    } else {
      this.connectorBackend.put_store(this.store).subscribe(
        () => this.router.navigate(['/admin/']),
        err => console.log(err)
      );
    }
  }
}
