<div class="row justify-content-between">
  <div class="col">
    <h3>Connected stores</h3>
  </div>
  <div class="col-auto">
    <button class="btn btn-outline-success" routerLink="/admin/add">Add new store</button>
  </div>
</div>
<div class="row">
  <div class="col">
    <table class="table">
      <thead>
        <tr>
          <th>Name</th>
          <th>J.Lindeberg Customer Number</th>
          <th>Email</th>
          <th>Vendo Store code</th>
          <th>Sitoo Instance</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let store of stores" on-click="goToStore(store.key)">
          <td>{{ store.name }}</td>
          <td>{{ store.customerNo }}</td>
          <td>{{ store.email }}</td>
          <td>{{ store.key }}</td>
          <td><span *ngIf="store.sitoo != null ">{{ store.sitoo.name }}</span></td>
        </tr>
      </tbody>
    </table>
  </div>
</div>