import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { StoreComponent } from './store.component';
import { OrderComponent } from './order/order.component';
import { OrderListComponent } from './orderList/orderList.component';
import { PageNotFoundComponent } from '../page-not-found/page-not-found.component';

const routes: Routes = [
  { path: 'store/:store', component: StoreComponent,
    children: [
      { path: '', component: OrderListComponent },
      { path: 'order/:id', component: OrderComponent },
  ]},
  { path: '**', component: PageNotFoundComponent }
];

@NgModule({
  imports: [
    RouterModule.forChild(routes)
  ],
  exports: [RouterModule]
})
export class StoreRoutingModule { }
