export class Address {
    constructor(
        public id?: number,
        public firstName?: string,
        public familyName?: string,
        public address1?: string,
        public zip?: string,
        public city?: string,
        public countryCode?: string,
        public address2?: string,
        public state?: string,
) {}
}
