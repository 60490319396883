
import {interval as observableInterval,  Subject ,  Subscription ,  Observable } from 'rxjs';

import {map} from 'rxjs/operators';
import { Component, OnInit, OnDestroy, AfterViewInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';


import { StoreService } from './store.service';
import { WebsocketService } from './websocket.service';

import { Store } from './store';
import { Order } from './order';
import { TraceClassDecorator } from '@sentry/angular';

@Component({
  selector: 'app-store',
  templateUrl: './store.component.html',
  styleUrls: ['./store.component.css']
})
@TraceClassDecorator()
export class StoreComponent implements OnInit, OnDestroy, AfterViewInit {
  private sub: Subscription;
  messages: Subject<any>;

  store: Store;
  private _store: Store;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private storeService: StoreService,
    private wsService: WebsocketService,
    private location: Location
  ) {
    this.store = this.storeService.store.getValue();
    this.sub = this.storeService.subStore()
      .subscribe(store => {
        this._store = store;
      });
  }

  ngOnInit() {
    this.sub = this.route.params.subscribe(params => {
      const storeId = params['store'];
      this.messages = <Subject<any>>this.wsService
      .connect(storeId).pipe(
      map((response: any): any => {
        return response;
      }));
      this.storeService.key = storeId;
      this.storeService.getStore();

      this.storeService.getOrders().subscribe();

      this.messages.subscribe(msg => {
        this.storeService.getOrders().subscribe();
        this.storeService.getStore();
      });
    });
  }

  goToOrder(storeKey: string, order: Order) {
    this.router.navigate(['/' + storeKey + '/order/' + order.id]);
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }

  ngAfterViewInit(): void {
    observableInterval(1000)
      .subscribe(i => {
        this.store = this._store;
      });
  }
}
