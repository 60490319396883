import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TraceClassDecorator } from '@sentry/angular';

import { AuthService } from '../auth.service';

class User {
  password: string;
}

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
@TraceClassDecorator()
export class LoginComponent implements OnInit {

  user = new User();
  constructor(private router: Router, private auth: AuthService) {}
  onLogin(): void {
    if (this.auth.login(this.user.password)) {
      this.router.navigateByUrl('/admin');
    }
  }

  ngOnInit() {
  }

}
