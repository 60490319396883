<div class="row">
    <div class="col-md-4">
      <h1>Login</h1>
      <hr><br>
      <form (ngSubmit)="onLogin()" novalidate id='login'>
       <div class="form-group">
         <label for="password">Password</label>
         <input type="password" class="form-control" id="password" placeholder="enter password" [(ngModel)]="user.password" name="password" required>
       </div>
       <button type="submit" class="btn btn-default">Login</button>
      </form>
    </div>
  </div>