import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { Store } from '../store';
import { ConnectorBackendService } from '../connector-backend.service';
import { TraceClassDecorator } from '@sentry/angular';

@Component({
  selector: 'app-admin-store-list',
  templateUrl: './admin-store-list.component.html',
  styleUrls: ['./admin-store-list.component.css']
})
@TraceClassDecorator()
export class AdminStoreListComponent implements OnInit {
  stores: Store[];
  constructor(
    private router: Router,
    private connectorBackend: ConnectorBackendService
  ) { }

  ngOnInit() {
    this.connectorBackend.get_stores().subscribe(stores => this.stores = stores);
  }

  goToStore(storeKey: string) {
    this.router.navigate(['/admin/' + storeKey ]);
  }
}
