import { Injectable } from '@angular/core';
import { HttpClient} from '@angular/common/http';

import { BehaviorSubject ,  Subscription ,  Observable ,  of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';

import { Store } from './store';
import { Order } from './order';

@Injectable()
export class StoreService {
  status = 1;
  key: string;
  orders = new BehaviorSubject<Order[]>([]);
  store = new BehaviorSubject<Store>(new Store());

  constructor(private http: HttpClient) { }
  getStore(): Subscription {

    return this.http
      .get('api/' + this.key)
      .subscribe(
        (data: Store) => {
          this.store.next(data);
        },
        err => this.handleError(err)
      );
  }

  getOrders() {
    let url = 'api/' + this.key + '/orders';
    if (this.status) {
      url += '?status=' + this.status.toString();
    }
    return this.http
      .get<Order[]>(url)
      .pipe(
        tap(res => {this.orders.next(res); }),
        catchError(this.handleError('updateHero'))
      );
  }

  setStatus(status: number) {
    this.status = status;
  }

  getStatus(): number {
    return this.status;
  }

  subStore() {
    return this.store.asObservable();
  }

  subOrders() {
    return this.orders.asObservable();
  }

  private handleError<T> (operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {

      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead

      // TODO: better job of transforming error for user consumption
      // this.log(`${operation} failed: ${error.message}`);

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }
}
