import { Injectable } from '@angular/core';
import { Router, CanActivate } from '@angular/router';

import { environment } from '../../environments/environment';

@Injectable()
export class AuthService implements CanActivate {

  private loginUtil: Date;

  constructor(public router: Router) { }

  public canActivate(): boolean {
    if (this.loginUtil === undefined || this.loginUtil < new Date(Date.now())) {
      console.log('not logged in');
      this.router.navigate(['login']);
      return false;
    }
    return true;
  }

  public login(password: string): boolean {

    if (password === environment.password) {
      this.loginUtil = new Date(Date.now() + 3 * 60 * 60 * 1000);
      return true;
    }
    return false;
  }

  public logout() {
    this.loginUtil = undefined;
    this.router.navigate(['login']);
  }
}
