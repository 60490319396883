<div class="container-fluid">
    <div class="row">
        <div class="col store d-flex flex-column justify-content-center">
            <h1>J.LINDEBERG</h1>
            <h3>ADMIN PAGE</h3>
            <div class="mt-auto p-2">
                <button class="btn btn-danger" type="button" id="logout" (click)="logout()">Logout</button>
            </div>
            <div class="mt-auto p-2">
                <hr>
                <h4>SUPPORT VERTISEIT</h4>
                <p>+46 (0)340-84812 <br />support@vertiseit.se</p>
            </div>
        </div>
        <div class="col-9 content">
            <router-outlet></router-outlet>
        </div>
    </div>
</div>